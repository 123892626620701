import React, { useContext } from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import SEO from "../../components/Layout/SEO";
import SuccessStories from "../../sections/camelo/SuccessStories";

import bgSection from "../../assets/image/customers/next_wave_neurodiagnostics_customer_story_feature_image.png";

const MiddleCTA = () => {
  return (
    <Row className="bg-default-1 border-top">
      <Container>
        <Col xs="12" className="text-center pb-24">
          <h2 className="gr-text-5 pt-24 pb-8">
            <span
              className="highlighted1"
              style={{ backgroundPosition: "0 20px" }}
            >
              Try Camelo now and see the difference yourself.
            </span>
          </h2>
          <div>
            <Link
              onClick={() => {
                if (typeof window.gtag_report_conversion === "function") {
                  window.gtag_report_conversion(process.env.SIGNUP_URL);
                }
              }}
              to={process.env.SIGNUP_URL}
              target="__blank"
              className="btn btn-primary with-icon gr-hover-y"
            >
              Start scheduling free
              <i className="icon icon-tail-right font-weight-bold"></i>
            </Link>
            <p className="gr-text-12 pt-8">
              59 companies signed up in the last week alone!
            </p>
          </div>
        </Col>
      </Container>
    </Row>
  );
};

const NextWaveStory = () => {
  return (
    <>
      <PageWrapper>
        <SEO title="How Next Wave Neurodiagnostics overcomes scheduling challenges with Camelo"></SEO>
        <div className="inner-banner bg-default-6 pt-24 pt-lg-30">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9">
                <div className="px-md-12 mb-13 text-center">
                  <h1 className="title gr-text-4 mb-8 mb-lg-12">
                    How Next Wave Neurodiagnostics overcomes scheduling
                    challenges with Camelo
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className="video-section py-15 py-sm-20 py-lg-35 dark-mode-texts "
          css={`
            background-image: url(${bgSection});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          `}
        />

        <div className="pb-2 pb-lg-9 pt-10 pt-lg-15 bg-default-6 customer-story">
          <Container>
            <Row className="justify-content-center">
              <Col xl="10" lg="10">
                <div className="px-md-12 text-black mb-13 mb-lg-22">
                  <p className="gr-text-8 text-black">
                    <Link to="https://www.nwaven.com" target="__blank">
                      {`Next Wave Neurodiagnostics `}
                    </Link>
                    specializes in providing staffing services for
                    neurodiagnostic specialties, performing NCS, EEG, SSEP, and
                    SSR tests to evaluate nerve and brain function. With over a
                    decade of experience, they’re dedicated to making advanced
                    neurodiagnostic services more accessible and reliable for
                    clinicians and patients alike.
                  </p>
                  <p className="gr-text-8 text-black">
                    Scheduling once became a challenge for their growing team of
                    contractors, as using Google Sheets or Google Calendar to
                    organize shifts became so overwhelming. After many struggles
                    to find a better solution, Camelo has become the perfect fit
                    for Next Wave Neurodiagnostics’ scheduling needs.
                  </p>
                  <h4 className="pt-8 pb-4">
                    Tackling the inefficiencies within the industry
                  </h4>
                  <p className="gr-text-8 text-black">
                    Osvaldo Johnson—the owner of Next Wave Neurodiagnostics,
                    Inc.—began his career in 2009, working for another company
                    as a contractor doing Neurodiagnostic studies. In 2011, he
                    decided to go on his own because he noticed there were a lot
                    of inefficiencies within the industry and he knew he could
                    do better.
                  </p>
                  <p className="gr-text-8 text-black">
                    <blockquote className="blockquote mb-4 card-body">
                      <p className="text-black pt-4 gr-text-6 lead">
                        “I work with Pain Management Doctors and Neurologists
                        doing studies that help diagnose many neurological
                        issues like ALS, peripheral neuropathies, and
                        radiculopathies.”
                      </p>
                    </blockquote>
                  </p>
                  <h4 className="pt-8 pb-4">
                    Facing scheduling challenges as the business scaled
                  </h4>
                  <p className="gr-text-8 text-black">
                    “I have tried different methods to keep things nice and easy
                    but human error would always come into play and mistakes
                    kept happening,” Osvaldo shared.
                  </p>
                  <p className="gr-text-8 text-black">
                    Google Sheets, Microsoft Excel, Whiteboard, and Google
                    Calendar had worked for them until the number of contractors
                    became too large to manage with Google Calendar. Calengoo
                    helped, but the features were limited.
                  </p>
                  <p className="gr-text-8 text-black">
                    As the business grew, so did the scheduling challenges.
                    "Communication about who was available and on what days was
                    always an issue," the owner said.
                  </p>
                  <p className="gr-text-8 text-black">
                    Using tools like Google Sheets, Microsoft Excel, and Google
                    Calendar initially worked, but as the team expanded, these
                    tools weren’t cutting it anymore. Scheduling errors—like
                    assigning the wrong person to shifts or missing updates—were
                    common. "Also using the dates booked to do our billing was
                    no easy task either," Osvaldo added.
                  </p>
                  <h4 className="pt-8 pb-4">Finding a better solution</h4>
                  <p className="gr-text-8 text-black">
                    It took Osvaldo quite a while to find a tool that could
                    fulfill their needs in scheduling and management. One day,
                    they found out about Camelo and decided to give
                    it a chance. Osvaldo spent a few days getting started and
                    learning the basics to begin scheduling on the app.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “After a while, Camelo became pretty straightforward and
                      intuitive to use.”
                    </p>
                  </blockquote>
                  <h4 className="pt-8 pb-4">
                    How Camelo boosts efficiency and team coordination
                  </h4>
                  <p className="gr-text-8 text-black">
                    One of the standout features from Camelo that Next Wave
                    Neurodiagnostics finds useful has been the ability to
                    organize schedules by job site or by employee. Before
                    Camelo, they could only view appointments in one way, which
                    made it harder to get a full picture of the workweek.
                  </p>
                  <p className="gr-text-8 text-black">
                    Now, they can switch views depending on the situation,
                    allowing them to understand who’s where better. "This
                    feature has helped us organize and manage our team more
                    effectively," Osvaldo shared.
                  </p>
                  <p className="gr-text-8 text-black">
                    They’ve also found the open shift feature incredibly helpful
                    for keeping track of unfilled appointments.
                  </p>
                  <h4 className="pt-8 pb-4">
                    Favorite features that improve communication &
                    accountability
                  </h4>
                  <p className="gr-text-8 text-black">
                    <Link
                      to="https://camelohq.com/features/workplace-communication"
                      target="__blank"
                    >
                      {`Camelo’s notifications `}
                    </Link>
                    and shift acceptance requirements have been game-changers
                    for Next Wave Neurodiagnostics. These tools help ensure
                    everyone is aware of their schedule and reduce the risk of
                    miscommunication.
                  </p>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      "The support team has been super helpful, always answering
                      my questions and finding ways for me to use the app
                      better."
                    </p>
                  </blockquote>
                  <h4 className="pt-8 pb-4">
                    Making scheduling easier with Camelo
                  </h4>
                  <p className="gr-text-8 text-black">
                    With Camelo, the business owner can now get a quick overview
                    of who’s available and where they’re scheduled to be. "The
                    app made it so much easier to manage available jobs and get
                    a bird's-eye view of the week for each employee," Osvaldo
                    explained. The scheduling process has become faster, more
                    organized, and less prone to errors.
                  </p>
                  <p className="gr-text-8 text-black">
                    Would they recommend Camelo? Absolutely.
                  </p>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      "I’d definitely recommend Camelo to others. There’s a lot
                      to gain by using a scheduling app like this. While there
                      are features I think could make it even better, I’m sure
                      some of those are in development."
                    </p>
                  </blockquote>
                  <h4 className="pt-8 pb-4">
                    Looking ahead: Growing with Camelo to meet future needs
                  </h4>
                  <p className="gr-text-8 text-black">
                    As Next Wave Neurodiagnostics continues to grow, they’re
                    excited to keep using Camelo. "As we use it more, we’ll
                    continue to suggest features that could make running our
                    operations even easier," the owner added. With Camelo in
                    place, they’re confident their scheduling challenges are a
                    thing of the past, allowing them to focus more on what
                    matters most—providing top-tier neurodiagnostic care.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <MiddleCTA />
        <SuccessStories />
      </PageWrapper>
    </>
  );
};
export default NextWaveStory;
